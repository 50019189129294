/* #BOOTSTRAP AND MIXINS - Base Unmodified Bootstrap file with theme mixins
========================================================================== */
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables'; 
@import './node_modules/bootstrap/scss/mixins';
@import './src/scss/_mixins/mixins';

/* #BASE - Base Variable file along with font library, and colors.
========================================================================== */
@import './src/scss/_modules/_overrides';
@import './src/scss/_modules/variables';
@import './src/scss/_modules/_fonts';
@import './src/scss/_modules/_placeholders';
@import './src/scss/_modules/_custom';