/* #GLOBAL IMPORTS
========================================================================== */
@import '_imports/_global-import';

/* #FRAMEWORK - Structure and layout files. (**DO NOT** change order)
                DOC: you can disable unused _modules (may brake the page)
========================================================================== */
/* contains root variables to be used with css (see docs) */
@import '_modules/_root';
/* resets DOM elements to its natural state */
@import '_modules/_reset';
/* html and body base styles */
@import '_modules/_body';
/* app header */
@import '_modules/_page-header';
/* app logo */
@import '_modules/_page-logo';
/* app search */
@import '_modules/_page-search';
/* icon menu with user options */
@import '_modules/_dropdown-icon-menu';
/* dropdown notification in the app header */
@import '_modules/_dropdown-notification';
/* icon menu with stacked icons located in the app header */
@import '_modules/_dropdown-app-list';
/* app header stays fixed */
@import '_modules/_header-function-fixed';
/* app far left panel */
@import '_modules/_left-panel';
/* app navigation */
@import '_modules/_nav';
/* app navigation filter */
@import '_modules/_nav-listfilter';
/* app info card inside navigation */
@import '_modules/_nav-info-card';
/* app navigation made horizontal */
@import '_modules/_nav-function-top';
/* app navgation stays hidden */
@import '_modules/_nav-function-hidden';
/* app navigation stays fixed */
@import '_modules/_nav-function-fixed';
/* app navigation stays minified */
@import '_modules/_nav-function-minify';
/* app navigation footer */
@import '_modules/_nav-footer';
/* app wrapper */
@import '_modules/_page-wrapper';
/* app content heading */
@import '_modules/_page-heading';
/* app content */
@import '_modules/_page-content';
/* app footer */
@import '_modules/_page-footer';
/* app footer stays fixed */
@import '_modules/_page-footer-fixed';
/* app error page */
@import '_modules/_page-error';
/* various app components (see docs for the full list) */
@import "_modules/_page-components-accordion.scss";
@import "_modules/_page-components-alerts.scss";
@import "_modules/_page-components-badge.scss";
@import "_modules/_page-components-breadcrumb.scss";
@import "_modules/_page-components-buttons.scss";
@import "_modules/_page-components-cards.scss";
@import "_modules/_page-components-carousel.scss";
@import "_modules/_page-components-dropdowns.scss";
@import "_modules/_page-components-icon-stack.scss";
@import "_modules/_page-components-listfilter.scss";
@import "_modules/_page-components-loader.scss";
@import "_modules/_page-components-messanger.scss";
@import "_modules/_page-components-modal.scss";
@import "_modules/_page-components-pagination.scss";
@import "_modules/_page-components-panels.scss";
@import "_modules/_page-components-popovers.scss";
@import "_modules/_page-components-progressbar.scss";
@import "_modules/_page-components-shortcut.scss";
@import "_modules/_page-components-side-panels.scss";
@import "_modules/_page-components-tables.scss";
@import "_modules/_page-components-tabs.scss";
@import "_modules/_page-components-tooltips.scss";

/* #MISC - misc styles, helpers, effects and hacks
========================================================================== */ 
@import '_modules/_helpers';
@import '_modules/_misc';
@import '_modules/_effects';
@import '_modules/_hack';
@import '_modules/_hack-ie';

/* #MOBILE - mobile media related styles
========================================================================== */ 
/* contains most of the responsive styles for the app */
@import '_modules/_responsive';
/* changes content colors based on ambience light source of the user (experimental) */
@import '_modules/_light-levels';

/* #FORMS (customized bootstrap form elems)
========================================================================== */ 
@import '_modules/_forms';

/* #COMPONENTS (can be removed but may or may not impact other components)
========================================================================== */ 
/*@import '_modules/_form-switches';*/
@import '_modules/_translate-3d';

/* #DEMO ELEMS - elements mostly used for demo (can be removed)
========================================================================== */ 
@import "_modules/_settings-demo-incompatiblity-list.scss";
@import "_modules/_settings-demo-theme-colors.scss";
@import "_modules/_settings-demo.scss";
@import '_modules/_demo-only';

/* #_extensions - Components imported in alphabetical order (remove extensions from directory if not needed)
========================================================================== */ 
@import "_extensions/_extension-pace.scss";
@import "_extensions/_extension-slimscroll.scss";
@import "_extensions/_extension-waves.scss";

/* #ANIMATION - CSS animations and keyframes
========================================================================== */ 
@import "_modules/_keyframes-general.scss";
@import "_modules/_keyframes-highlight.scss";
@import "_modules/_keyframes-spinner.scss";
@import "_modules/_keyframes-transition.scss";

/* #MODS - Layout manipulation
========================================================================== */ 
@import "_modules/_mod-bg.scss";
@import "_modules/_mod-clean-page-bg.scss";
@import "_modules/_mod-colorblind.scss";
@import "_modules/_mod-disable-animation.scss";
@import "_modules/_mod-hide-info-card.scss";
@import "_modules/_mod-hide-nav-icons.scss";
@import "_modules/_mod-high-contrast.scss";
@import "_modules/_mod-lean-page-header.scss";
@import "_modules/_mod-main-boxed.scss";
@import "_modules/_mod-nav-accessibility.scss";
@import "_modules/_mod-nav-dark.scss";
@import "_modules/_mod-smartpanel-icons.scss";
@import "_modules/_mod-text-size.scss";

/* #COLORS - we place this here so it can override other colors as needed
========================================================================== */ 
@import '_modules/_colors';

/* #APP related modules (print, fullscreen, etc)
========================================================================== */ 
@import "_modules/_app-custom-scrollbar.scss";
@import "_modules/_app-fullscreen.scss";
@import "_modules/_app-print.scss";